import React from "react";
import { navigate } from "gatsby";
import { AuthProvider } from "../../../../components/Auth/auth";
import DashboardLayout from "../../../../components/DashboardLayout";
import SimpleSpring from "../../../../components/Transitions/transitions";
import AuctionCreationNav from "../../../../components/PageComponents/Dashboard/Auctions/AuctionCreation/internal/AuctionCreationNav";
import AddImageStep from "../../../../components/PageComponents/Dashboard/Auctions/AuctionCreation/steps/AddImageStep";

const AuctionCreateStep4 = ({ location }) => (
  <AuthProvider>
    <SimpleSpring location={location}>
      <DashboardLayout
        hideFooter
        style={{ paddingTop: 0 }}
        hideNav
        title="Bidddy | Create New Auction - Add Image"
      >
        <AuctionCreationNav
          onBack={() => navigate("/dashboard/auctions/create/add-settings/")}
          step={2}
          totalSteps={6}
          title="Add Image"
        />
        <AddImageStep />
      </DashboardLayout>
    </SimpleSpring>
  </AuthProvider>
);

export default AuctionCreateStep4;
