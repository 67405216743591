/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState, useEffect } from "react";
import ImageUploading from "react-images-uploading";
import cx from "classnames";
import { useSelector } from "react-redux";
import Imgix from "react-imgix";
import "cropperjs/dist/cropper.css";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import { navigate } from "gatsby";
import { useAppDispatch } from "../../../../../../store";
import {
  getAuction,
  getUploadImageData,
  getUploadState,
  getAuctionModalLoadingState,
  getAuctionModalIsEditing,
} from "../../../../../../store/auctionModal/selectors";
import styles from "./AuctionSteps.module.scss";
import BasicTable from "../../../../../Table/BasicTable";
import BasicTableBody from "../../../../../Table/BasicTableBody";
import BasicTableRow from "../../../../../Table/BasicTableRow";
import { Auction } from "../../../../../../types/Auction";
import {
  getUploadImageUrl,
  uploadImage,
  UploadProperties,
} from "../../../../../../store/auctionModal/actions";
import { removeAuctionImage } from "../../../../../../store/auctionModal/reducer";
import { sendToast } from "../../../../../../utils/helpers";
import AuctionButtonsContainer from "../internal/AuctionButtonsContainer";
import {
  selectAvailableMarkets,
  selectRequestingUserInfo,
  selectUserInfo,
} from "../../../../../../store/user/selectors";
import { Market } from "../../../../../../types/Market";
import { getUserInfo } from "../../../../../../store/user/actions";
import FullScreenLoading from "../../../../../Loading/FullScreenLoading";
import Cropper from "./internal/Cropper/Cropper";

const IMGIX_PLACEHOLDER = `https://imgix.cosmicjs.com/52011e20-e03b-11ec-bb19-d9085ce408df-AddImage.png`;
const MAX_IMAGES = 5;

const ImageIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="icon"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0013 21.0827C5.43243 21.0827 0.917969 16.5682 0.917969 10.9993C0.917969 5.43048 5.43243 0.916016 11.0013 0.916016C16.5702 0.916016 21.0846 5.43048 21.0846 10.9993C21.0846 16.5682 16.5702 21.0827 11.0013 21.0827ZM11.0017 19.2492C15.558 19.2492 19.2517 15.5555 19.2517 10.9992C19.2517 6.44282 15.558 2.74917 11.0017 2.74917C6.44531 2.74917 2.75166 6.44282 2.75166 10.9992C2.75166 15.5555 6.44531 19.2492 11.0017 19.2492ZM15.5846 10.0827H11.918V6.41602H10.0846V10.0827H6.41797V11.916H10.0846V15.5827H11.918V11.916H15.5846V10.0827Z"
      fill="#BFBFBF"
    />
  </svg>
);

const SMALL_ADD_IMAGE =
  "https://imgix.cosmicjs.com/6a36a190-cafb-11ec-aaa1-f1e7c930cb45-SmallAddImage.png";

const AddImageStep = () => {
  const dispatch = useAppDispatch();
  const auction = useSelector((state) => getAuction(state)) as Auction;
  const uploadState = useSelector((state) => getUploadState(state)) as string;
  const imageData = useSelector((state) => getUploadImageData(state));
  const loading = useSelector((state) =>
    getAuctionModalLoadingState(state)
  ) as boolean;
  const isEditing = useSelector((state) =>
    getAuctionModalIsEditing(state)
  ) as boolean;
  const userInfo = useSelector((state) => selectUserInfo(state));
  const requestingUserInfo = useSelector((state) =>
    selectRequestingUserInfo(state)
  );
  const availableMarkets = useSelector((state) =>
    selectAvailableMarkets(state)
  ) as Market[];
  const [selectedMarket, setSelectedMarket] = useState("");
  const [localLoading, setLocalLoading] = useState(false);
  const [visibleImage, setVisibleImage] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [imageUploadCount, setImageUploadCount] = useState(0);
  const [currentImageUploadPosition, setCurrentImageUploadPosition] =
    useState(0);

  useEffect(() => {
    if (!userInfo && !requestingUserInfo) {
      dispatch(getUserInfo());
    }
  }, []);

  useEffect(() => {
    if (availableMarkets.length > 0) {
      const selectedAuctionMarket = availableMarkets.find(
        (market) => market.id === auction.market_id
      );
      if (selectedAuctionMarket) {
        setSelectedMarket(selectedAuctionMarket.name);
      }
    }
    if (auction.item && auction.item.files) {
      if (auction.item.files.length < visibleImage) {
        setVisibleImage(0);
      }
    }
  }, [availableMarkets, auction]);

  const [images, setImages] = useState([]);
  //  Cropping
  const [filename, setFilename] = useState(null);
  const [cropFile, setCropFile] = useState(null);
  const [showCrop, setShowCrop] = useState(false);

  useEffect(() => {
    if (uploadState === "UPLOAD_SUCCESS") {
      // setImages([]);
    }
  }, [uploadState]);

  useEffect(() => {
    if (imageData && cropFile) {
      const uploadProperties = {
        uploadUrl: imageData.data.url,
        file: cropFile,
      } as UploadProperties;
      dispatch(uploadImage(uploadProperties));
      setUploading(false);
    }
  }, [imageData]);

  const onChange = (imageList) => {
    if (uploading) {
      return;
    }
    setUploading(true);
    setFilename(imageList[0].file.name);
    setShowCrop(true);
    setImages(imageList);
    setImageUploadCount(imageList.length);
    setCurrentImageUploadPosition(0);
  };

  const onNext = () => {
    if (
      auction &&
      auction.item &&
      auction.item.files &&
      auction.item.files.length > 0
    ) {
      if (isEditing) {
        navigate(`/dashboard/auctions/${auction.id}/preview/`);
      } else {
        navigate("/dashboard/auctions/create/preview/");
      }
    } else {
      sendToast("You must add at least one image.", { type: "warning" });
    }
  };
  // On Cancel
  const onCropCancel = () => {
    setUploading(false);
    setShowCrop(false);
  };

  const onCropSave = async (dataUrl) => {
    setLocalLoading(true);
    const blob = await (await fetch(dataUrl)).blob();
    const imgType = blob.type;
    let type = "png";
    if (imgType === "image/jpeg" || imgType === "image/jpg") {
      type = "jpg";
    }
    const file = new File([blob], filename, {
      type: imgType,
      lastModified: new Date().getMilliseconds(),
    });

    dispatch(getUploadImageUrl(type));
    setCropFile(file);
    setLocalLoading(false);
    if (currentImageUploadPosition < imageUploadCount - 1) {
      setCurrentImageUploadPosition(currentImageUploadPosition + 1);
    } else {
      setCurrentImageUploadPosition(0);
      setShowCrop(false);
    }
  };

  const removeImage = (e, item) => {
    e.stopPropagation();
    e.preventDefault();
    setVisibleImage(0);
    dispatch(removeAuctionImage(item));
  };

  const setSelectedImage = (e, index) => {
    e.stopPropagation();
    setVisibleImage(index);
  };
  const hasFiles =
    auction.item && auction.item.files && auction.item.files.length > 0;
  const hasFilesAndIsVisible =
    auction &&
    auction.item &&
    auction.item.files &&
    auction.item.files.length > visibleImage;

  const hasZeroFiles =
    auction &&
    auction.item &&
    auction.item.files &&
    auction.item.files.length === 0;

  const hasUnknownFiles =
    auction &&
    auction.item &&
    (!auction.item?.files || auction.item.files?.length === 0);

  return (
    <>
      <div className={styles.creationContainer}>
        <div
          className={cx([
            "w-full flex flex-col",
            styles.creationImageContainer,
          ])}
        >
          <ImageUploading
            multiple
            value={[]}
            onChange={onChange}
            maxNumber={MAX_IMAGES}
            dataURLKey="data_url"
          >
            {({ onImageUpload, isDragging, dragProps }) => (
              <>
                <div className={styles.imageUploadContainer}>
                  <div className={styles.mobileOnly}>
                    <h3>@{selectedMarket}</h3>
                    <h2>Add Image(s)</h2>
                  </div>
                  {!isEditing && hasUnknownFiles && (
                    <div
                      tabIndex={0}
                      role="button"
                      onKeyDown={() => onImageUpload()}
                      className={styles.imageUploader}
                      onClick={() => onImageUpload()}
                      style={
                        isDragging ? { border: "2px solid #F9B903" } : null
                      }
                      {...dragProps}
                    >
                      <Imgix
                        height={1080}
                        width={1080}
                        src={`${IMGIX_PLACEHOLDER}?auto=format&w=1080&h=1080`}
                        htmlAttributes={{
                          alt: "Placeholder",
                          src: `${IMGIX_PLACEHOLDER}?&auto=format&w=1080&h=1080`,
                        }}
                        attributeConfig={{
                          src: "data-src",
                          srcSet: "data-srcset",
                          sizes: "data-sizes",
                        }}
                        className="lazyload blur-up"
                      />
                    </div>
                  )}
                  {isEditing && hasZeroFiles && (
                    <div
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => onImageUpload()}
                      className={styles.imageUploader}
                      onClick={() => onImageUpload()}
                      style={
                        isDragging ? { border: "2px solid #F9B903" } : null
                      }
                      {...dragProps}
                    >
                      <Imgix
                        height={1080}
                        width={1080}
                        src={`${IMGIX_PLACEHOLDER}?auto=format&w=1080&h=1080`}
                        htmlAttributes={{
                          alt: "Placeholder",
                          src: `${IMGIX_PLACEHOLDER}?auto=format&w=1080&h=1080`,
                        }}
                        attributeConfig={{
                          src: "data-src",
                          srcSet: "data-srcset",
                          sizes: "data-sizes",
                        }}
                      />
                    </div>
                  )}
                  {hasFilesAndIsVisible && (
                    <div
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => onImageUpload()}
                      className={styles.imageUploader}
                      onClick={() => onImageUpload()}
                    >
                      <Imgix
                        height={1080}
                        width={1080}
                        src={`${auction.item.files[visibleImage].url}`}
                        htmlAttributes={{
                          alt: "Uploaded image",
                          src: `${auction.item.files[visibleImage].url}`,
                        }}
                        attributeConfig={{
                          src: "data-src",
                          srcSet: "data-srcset",
                          sizes: "data-sizes",
                        }}
                        className="lazyload blur-up"
                      />
                    </div>
                  )}
                </div>
                <div
                  className={cx([
                    styles.uploadOptionsContainer,
                    styles.mobileOnly,
                  ])}
                >
                  <BasicTable>
                    <BasicTableBody>
                      <BasicTableRow onClick={() => onImageUpload()}>
                        <td>Add Image(s)</td>
                        <td className="flex flex-row justify-end">
                          <ImageIcon />
                        </td>
                      </BasicTableRow>
                      <BasicTableRow hideBorderBottom>
                        <td>
                          {hasFiles && (
                            <div className="flex flex-row items-center flex-wrap">
                              {hasFilesAndIsVisible &&
                                auction.item.files.map((image, index) => (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={(e) =>
                                      setSelectedImage(e, index)
                                    }
                                    className={cx([
                                      {
                                        [styles.selectedImage]:
                                          visibleImage === index,
                                      },
                                      styles.smallImageContainer,
                                    ])}
                                    key={`image-mobile-${image.id}`}
                                    onClick={(e) => setSelectedImage(e, index)}
                                  >
                                    <button
                                      type="button"
                                      aria-label="Remove"
                                      className={styles.removeButton}
                                      onClick={(e) => removeImage(e, image)}
                                    />
                                    <Imgix
                                      height={69}
                                      width={69}
                                      src={`${image.url}?auto=format&w=69&h=69`}
                                      htmlAttributes={{
                                        alt: "Uploaded Image",
                                        src: `${image.url}?auto=format&w=69&h=69`,
                                      }}
                                      attributeConfig={{
                                        src: "data-src",
                                        srcSet: "data-srcset",
                                        sizes: "data-sizes",
                                      }}
                                    />
                                  </div>
                                ))}
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => onImageUpload()}
                                onClick={() => onImageUpload()}
                              >
                                <Imgix
                                  height={69}
                                  width={69}
                                  src={`${SMALL_ADD_IMAGE}?auto=format&w=69&h=69`}
                                  htmlAttributes={{
                                    alt: "Placeholder",
                                    src: `${SMALL_ADD_IMAGE}?auto=format&w=69&h=69`,
                                  }}
                                  attributeConfig={{
                                    src: "data-src",
                                    srcSet: "data-srcset",
                                    sizes: "data-sizes",
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </td>
                      </BasicTableRow>
                    </BasicTableBody>
                  </BasicTable>
                </div>
              </>
            )}
          </ImageUploading>
        </div>
        <div className={cx([styles.textContainer, styles.desktopOnly])}>
          <div>
            <h3>@{selectedMarket}</h3>
            <h2>
              Add <br />
              Images.
            </h2>
            <p>Add single or multiple images to your auction.</p>
            <ImageUploading
              multiple
              value={[]}
              onChange={onChange}
              maxNumber={MAX_IMAGES}
              dataURLKey="data_url"
            >
              {({ onImageUpload }) => (
                <div
                  className={cx([
                    styles.uploadOptionsContainer,
                    styles.desktopOnly,
                  ])}
                >
                  <BasicTable>
                    <BasicTableBody>
                      <BasicTableRow hideBorderBottom>
                        <td>
                          {hasUnknownFiles && (
                            <div
                              role="button"
                              tabIndex={0}
                              onKeyDown={() => onImageUpload()}
                              className="flex flex-row items-center"
                              onClick={() => onImageUpload()}
                            >
                              <div>
                                <Imgix
                                  height={69}
                                  width={69}
                                  src={`${SMALL_ADD_IMAGE}?auto=format&w=1080&h=1080`}
                                  htmlAttributes={{
                                    alt: "Placeholder",
                                    src: `${SMALL_ADD_IMAGE}?auto=format&w=1080&h=1080`,
                                  }}
                                  attributeConfig={{
                                    src: "data-src",
                                    srcSet: "data-srcset",
                                    sizes: "data-sizes",
                                  }}
                                />
                              </div>
                              <p className={styles.addImageText}>
                                Click Here To Add Image
                              </p>
                            </div>
                          )}
                          {hasFiles && (
                            <div className="flex flex-row items-center flex-wrap">
                              {hasFilesAndIsVisible &&
                                auction.item.files.map((image, index) => (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={(e) => removeImage(e, image)}
                                    className={cx([
                                      {
                                        [styles.selectedImage]:
                                          visibleImage === index,
                                      },
                                      styles.smallImageContainer,
                                    ])}
                                    key={`${image.data.filename}`}
                                    onClick={(e) => setSelectedImage(e, index)}
                                  >
                                    <button
                                      aria-label="Remove"
                                      type="button"
                                      className={styles.removeButton}
                                      onClick={(e) => removeImage(e, image)}
                                    />
                                    <Imgix
                                      height={69}
                                      width={69}
                                      src={`${image.url}?auto=format&w=69&h=69`}
                                      htmlAttributes={{
                                        alt: "Uploaded Image",
                                        src: `${image.url}?auto=format&w=69&h=69`,
                                      }}
                                      attributeConfig={{
                                        src: "data-src",
                                        srcSet: "data-srcset",
                                        sizes: "data-sizes",
                                      }}
                                    />
                                  </div>
                                ))}
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => onImageUpload()}
                                onClick={() => onImageUpload()}
                              >
                                <Imgix
                                  height={69}
                                  width={69}
                                  src={`${SMALL_ADD_IMAGE}?auto=format&w=69&h=69`}
                                  htmlAttributes={{
                                    alt: "Placeholder",
                                    src: `${SMALL_ADD_IMAGE}?auto=format&w=69&h=69`,
                                  }}
                                  attributeConfig={{
                                    src: "data-src",
                                    srcSet: "data-srcset",
                                    sizes: "data-sizes",
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </td>
                      </BasicTableRow>
                    </BasicTableBody>
                  </BasicTable>
                </div>
              )}
            </ImageUploading>
          </div>
          <div className={cx([styles.learnContent, styles.buttonsBottom])}>
            <AuctionButtonsContainer
              disabled={hasZeroFiles}
              nextPage={() => onNext()}
            />
          </div>
        </div>

        {(loading || localLoading) && <FullScreenLoading />}
      </div>
      <div
        className={cx([
          styles.learnContent,
          styles.buttonsBottom,
          styles.mobileOnly,
        ])}
      >
        <AuctionButtonsContainer
          disabled={hasZeroFiles}
          nextPage={() => onNext()}
        />
      </div>
      {images.length > 0 && showCrop && (
        <Cropper
          images={images}
          imageIndex={currentImageUploadPosition}
          loading={localLoading}
          onSave={(dataUrl) => onCropSave(dataUrl)}
          onCancel={onCropCancel}
        />
      )}
    </>
  );
};

export default AddImageStep;
