import React, { useState } from "react";
import ImageCropper from "react-cropper";
import cn from "classnames";
import styles from "./Cropper.module.scss";

interface Cropper {
  images: any[];
  imageIndex: number;
  loading: boolean;
  onSave: (url: any) => any;
  onCancel: () => any;
}

const Cropper = ({
  images,
  imageIndex,
  onSave,
  loading,
  onCancel,
}: Cropper) => {
  const [cropper, setCropper] = useState<any>();
  const updateCropper = (ratio) => {
    cropper.setAspectRatio(ratio);
  };

  const onCropSave = () => {
    const canvas = cropper.getCroppedCanvas();
    if (canvas) {
      const dataUrl = canvas.toDataURL();
      onSave(dataUrl);
    }
  };

  return (
    <div
      className={cn([
        "absolute inset-0 flex flex-col justify-center",
        styles.cropContainer,
      ])}
    >
      <div className={styles.cropModal}>
        <h2>Crop Image</h2>
        <ImageCropper
          className={styles.cropperContainer}
          aspectRatio={1}
          initialAspectRatio={1}
          guides
          src={images[imageIndex].data_url}
          viewMode={0}
          zoomable
          scalable
          movable
          autoCrop
          modal
          dragMode="move"
          cropBoxMovable={false}
          cropBoxResizable={false}
          background={false}
          responsive
          autoCropArea={1}
          minCropBoxHeight={500}
          minCropBoxWidth={500}
          toggleDragModeOnDblclick={false}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={(instance) => {
            setCropper(instance);
          }}
        />
        {images.length > 0 && (
          <p className="text-center text-xs font-inter">
            Image {imageIndex + 1} of {images.length}
          </p>
        )}
        <p className="text-left w-full mt-4">Aspect Ratio:</p>
        <div className={styles.aspectRatios}>
          <div
            role="button"
            tabIndex={0}
            onKeyDown={() => updateCropper(1)}
            onClick={() => updateCropper(1)}
          >
            1:1
          </div>
          <div
            role="button"
            tabIndex={0}
            onKeyDown={() => updateCropper(4 / 5)}
            onClick={() => updateCropper(4 / 5)}
          >
            4:5
          </div>
          <div
            role="button"
            tabIndex={0}
            onKeyDown={() => updateCropper(1.91)}
            onClick={() => updateCropper(1.91)}
          >
            1.91:1
          </div>
        </div>
        <div className={styles.cropButtons}>
          <button type="button" onClick={onCancel}>
            Cancel
          </button>
          <button disabled={loading} type="button" onClick={onCropSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cropper;
